<template lang="html">

  <h1>Procena Kandidata</h1>
  <div class="header">

  <label for="godiste">Godište:</label>
  <input type="text" name="godiste" v-model="godiste">
  <label for="prebivaliste">Prebivalište:</label>
  <input type="prebivaliste" name="godiste"  v-model="prebivaliste">
  <label for="pozicija">Pozicija:</label>
  <input type="pozicija" name="godiste" v-model="pozicija">

    </div>

  <div class="form-group">
    <h3>Stepen edukacije - institucija:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      VSS &nbsp;&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansSSa" id="ansSSa" v-model="stepenSS" value="VSS" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      VS &nbsp;&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansSSb" id="ansSSb" v-model="stepenSS" value="VS" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      SSS&nbsp;&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansSSc" id="ansSSc" v-model="stepenSS" value="SSS" >
    </div>

  </div>

  <div class="form-group">
    <h3>Stepen poznavanja Engleskog:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansEnga" id="ansEnga" v-model="stepenEng" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansEngb" id="ansEngb" v-model="stepenEng" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansEngc" id="ansEngc" v-model="stepenEng" value="3" >
    </div>

  </div>

  <div class="form-group">
    <h3>Stepen poznavanja Nemačkog:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio"  v-model="stepenNem" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio"  v-model="stepenNem" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio"  v-model="stepenNem" value="3" >
    </div>

  </div>

  <div class="form-group">
    <h3>Poznavanje rada na računaru:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
            <input class="form-check-input" type="radio" name="ansRaca" id="ansRaca" v-model="stepenRacunar" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansRacb" id="ansRacb" v-model="stepenRacunar" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault2">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" name="ansRacc" id="ansRacc" v-model="stepenRacunar" value="3" >
    </div>

  </div>

  <div class="form-group">
    <h3>1. Izgled i samopouzdanje kandidata:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans1" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans1" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans1" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans1" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans1" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans1Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>2. Komunikativnost i izražavanje:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans2" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans2" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans2" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans2" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans2" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans2Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>3. Energičnost i ambicioznost:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans3" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans3" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans3" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans3" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans3" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans3Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>4. Sposobnost organizovanja vremena:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans4" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans4" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans4" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans4" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans4" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans4Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>5. Opredeljenost ka klijentu:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans5" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans5" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans5" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans5" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans5" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans5Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>6. Prilagodljivost i timski rad:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans6" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans6" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans6" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans6" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans6" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans6Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>7. Sklonost ka odlučivanju i prepoznavanje prioriteta:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans7" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans7" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans7" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans7" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans7" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans7Desc" ></textarea>
  </div>


  <div class="form-group">
    <h3>8. Radno iskustvo:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans8" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans8" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans8" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans8" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans8" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans8Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>9. Razlozi napuštanja prethodnih radnih mesta:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans9" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans9" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans9" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans9" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans9" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans9Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>10. Najveći rezultat u karijeri:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans10" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans10" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans10" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans10" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans10" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans10Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>11. Motivacija za ovaj posao:</h3>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      1&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans11" value="1" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      2&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans11" value="2" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      3&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans11" value="3" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      4&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans11" value="4" >
    </div>
    <div class="form-check">
      <label class="form-check-label" for="flexRadioDefault1">
      5&nbsp;
      </label>
      <input class="form-check-input" type="radio" v-model="ans11" value="5" >
    </div>

    <textarea class="form-control"  rows="3" v-model="ans11Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>12. Posedovanje vozačke dozvole:</h3>
    <textarea class="form-control"  rows="3" v-model="ans12Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>13. Otkazni rok:</h3>
    <textarea class="form-control"  rows="3" v-model="ans13Desc" ></textarea>
  </div>

  <div class="form-group">
    <h3>14. Finansijska očekivanja:</h3>
    <textarea class="form-control"  rows="3" v-model="ans14Desc" ></textarea>
  </div>

  <div class="validation">
    {{validation}}
  </div>
<button class="btn btn-primary" @click="submit()">Submit</button>
  <div v-if="!noPdf">



  <button class="btn btn-primary" @click="showPDFFun()">Prikazi PDF</button>

  <!-- ++++++++++++++++++++++++++++++++++++ -->

  <div id="pdf" v-if="showPDF">

    <div id="feedBackHeader">
    <div class="centered">
    <h3 ><b>Procena Kandidata</b></h3>
    </div>

    <div class="wrap">
    <p>Legenda: 1 - Osnovno, 2 - Srednje, 3 - Odlicno</p>
    <div class="container">
      <div class="">
        <p><b>Ime kandidata:</b> {{user.name}}</p>
        <p><b>Godiste:</b> {{godiste}}</p>
        <p><b>Prebivaliste:</b> {{prebivaliste}}</p>
        <p><b>Pozicija za koju konkuriše:</b> {{pozicija}}</p>
      </div>
      <div class="">
        <p><b>Stepen edukacije:</b> {{stepenSS}}</p>
        <p><b>Stepen poznavanja Engleskog:</b> {{stepenEng}}</p>
        <p><b>Stepen poznavanja Nemackog:</b> {{stepenNem}}</p>
        <p><b>Poznavanje rada na racunaru:</b> {{stepenRacunar}}</p>
      </div>
    </div>
    </div>

    <div class="questionAlt">
      <h3>Ukupna Ocena: {{ukupnaOcena}} ({{prosekOcena.toFixed(2)}})</h3>
    </div>

    </div>

    <div id="svaPitanja1">
      <div class="question">
        <h3>1. Izgled i samopouzdanje kandidata.</h3>
        <p><b>Ocena - </b>{{ans1}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans1Desc}}
          </span>
        </p>
      </div>
      <div class="questionAlt">
        <h3>2. Komunikativnost i izražavanje.</h3>
        <p><b>Ocena - </b>{{ans2}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans2Desc}}
          </span>
        </p>
      </div>

    </div>

    <div id="svaPitanja2">
      <div class="question">
        <h3>3. Energicnost i ambicioznost.</h3>
        <p><b>Ocena - </b>{{ans3}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans3Desc}}
          </span>
        </p>
      </div>

      <div class="questionAlt">
        <h3>4. Sposobnost organizovanja vremena.</h3>
        <p><b>Ocena - </b>{{ans4}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans4Desc}}
          </span>
        </p>
      </div>
    </div>

    <div id="svaPitanja3">
      <div class="question">
        <h3>5. Opredeljenost ka klijentu.</h3>
        <p><b>Ocena - </b>{{ans5}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans5Desc}}
          </span>
        </p>
      </div>
      <div class="questionAlt">
        <h3>6. Prilagodljivost i timski rad.</h3>
        <p><b>Ocena - </b>{{ans6}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans6Desc}}
          </span>
        </p>
      </div>
    </div>

    <div id="svaPitanja4">
      <div class="question">
        <h3>7. Sklonost ka odlucivanju i prepoznavanje prioriteta.</h3>
        <p><b>Ocena - </b>{{ans7}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans7Desc}}
          </span>
        </p>
      </div>
      <div class="questionAlt">
        <h3>8. Radno iskustvo.</h3>
        <p><b>Ocena - </b>{{ans8}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans8Desc}}
          </span>
        </p>
      </div>
    </div>

    <div id="svaPitanja5">
      <div class="question">
        <h3>9. Razlozi napustanja prethodnih radnih mesta.</h3>
        <p><b>Ocena - </b>{{ans9}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans9Desc}}
          </span>
        </p>
      </div>
      <div class="questionAlt">
        <h3>10. Najveci rezultat u karijeri.</h3>
        <p><b>Ocena - </b>{{ans10}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans10Desc}}
          </span>
        </p>
      </div>
    </div>

    <div id="svaPitanja6">
      <div class="question">
        <h3>11. Motivacija za ovaj posao.</h3>
        <p><b>Ocena - </b>{{ans11}}</p>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans11Desc}}
          </span>
        </p>
      </div>
      <div class="questionAlt">
        <h3>12. Posedovanje vozacke dozvole.</h3>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans12Desc}}
          </span>
        </p>
      </div>
    </div>

    <div id="svaPitanja7">
      <div class="question">
        <h3>13. Otkazni rok.</h3>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans13Desc}}
          </span>
        </p>
      </div>
      <div class="questionAlt">
        <h3>14. Finansijska  ocekivanja.</h3>
        <p  style="text-align: left;">
          <span style="white-space: pre-line">
          <b>Komentar: </b>{{ans14Desc}}
          </span>
        </p>
      </div>
    </div>












  </div>

  <button class="btn btn-primary" @click="makePDF360()">Make PDF</button>
  </div>
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {mapActions,mapGetters} from 'vuex';
// eslint-disable-next-line no-unused-vars
import html2canvas from 'html2canvas';
// eslint-disable-next-line no-unused-vars
import jsPDF from 'jspdf';

export default {
  computed: mapGetters(["usersSameCompany"]),
  props:{
    user:{
      type: Object
    },
    noPdf:{
      type:Boolean
    }
  },
  data(){
    return{

      allowClick:false,
      showPDF:false,
      loadedUser:{},
      validation:"",
      godiste:"",
      prebivaliste:"",
      pozicija:"",
      stepenSS:"",
      stepenEng:"",
      stepenNem:"",
      stepenRacunar:"",
      ans1:"",
      ans1Desc:"",
      ans2:"",
      ans2Desc:"",
      ans3:"",
      ans3Desc:"",
      ans4:"",
      ans4Desc:"",
      ans5:"",
      ans5Desc:"",
      ans6:"",
      ans6Desc:"",
      ans7:"",
      ans7Desc:"",
      ans8:"",
      ans8Desc:"",
      ans9:"",
      ans9Desc:"",
      ans10:"",
      ans10Desc:"",
      ans11:"",
      ans11Desc:"",
      ans12:"",
      ans12Desc:"",
      ans13:"",
      ans13Desc:"",
      ans14:"",
      ans14Desc:"",
      ukupnaOcena:0,
      procesnaOcena:0,
    }
  },
  methods:{
    ...mapActions(["submitProcenaKandidata","getCollegues"]),
    showPDFFun(){
      if(this.allowClick){
        this.validation="";
      if(this.showPDF){
        this.showPDF=false;
      }else{
        this.showPDF=true;
      }
    }else{
      this.validation="Ne postoji Procena.";
    }
      },
    async submit(){

      this.validation="";

      let procenaData = {
        godiste:this.godiste,
        prebivaliste:this.prebivaliste,
        pozicija:this.pozicija,
        stepenSS:this.stepenSS,
        stepenEng:this.stepenEng,
        stepenNem:this.stepenNem,
        stepenRacunar:this.stepenRacunar,
        ans1:this.ans1,
        ans1Desc:this.ans1Desc,
        ans2:this.ans2,
        ans2Desc:this.ans2Desc,
        ans3:this.ans3,
        ans3Desc:this.ans3Desc,
        ans4:this.ans4,
        ans4Desc:this.ans4Desc,
        ans5:this.ans5,
        ans5Desc:this.ans5Desc,
        ans6:this.ans6,
        ans6Desc:this.ans6Desc,
        ans7:this.ans7,
        ans7Desc:this.ans7Desc,
        ans8:this.ans8,
        ans8Desc:this.ans8Desc,
        ans9:this.ans9,
        ans9Desc:this.ans9Desc,
        ans10:this.ans10,
        ans10Desc:this.ans10Desc,
        ans11:this.ans11,
        ans11Desc:this.ans11Desc,
        ans12:this.ans12,
        ans12Desc:this.ans12Desc,
        ans13:this.ans13,
        ans13Desc:this.ans13Desc,
        ans14:this.ans14,
        ans14Desc:this.ans14Desc,
        id:this.user._id
      }

      if(procenaData.godiste == "" || procenaData.prebivaliste =="" || procenaData.pozicija==""
      || procenaData.stepenSS == "" || procenaData.stepenEng == "" || procenaData.stepenNem == "" || procenaData.stepenRacunar == ""
      || procenaData.ans1 == "" || procenaData.ans2 == "" || procenaData.ans3 == "" || procenaData.ans4 == ""
      || procenaData.ans5 == "" || procenaData.ans6 == "" || procenaData.ans7 == "" || procenaData.ans8 == ""
      || procenaData.ans9 == "" || procenaData.ans10 == "" || procenaData.ans11 == ""
      ){
        this.validation=`Sva polja moraju biti popunjena!`;
      }else{
        await this.submitProcenaKandidata(procenaData).then(res=>{
          if(res.data.success){
            this.validation=`Uspešno sačuvane izmene`;
            this.allowClick=true;
          }
        }).catch(err =>{
          this.validation="Došlo do nepoznate greške. Pokušajte ponovo.";
          console.log(err);
        });


      }





    },
    async makePDFVQ(){

      var downloading = document.getElementById("pdf");
      var doc = new jsPDF("p","pt","a3");

      await html2canvas(downloading,{
        scale: 2,
        allowTaint : true,
        useCORS: true,
        height:1190,
        width:840,
      }).then((canvas)=>{
        doc.addImage(canvas.toDataURL("image/png"),'PNG',0,0,840, 1190);
      });


      doc.save("Document.pdf");

    },
    makePDF360(){
      this.validation="";
      if(this.showPDF){

      window.html2canvas=html2canvas;
      //p = portrait (moze i l za landscape)
      //pt za measurement
      //a4 za format
      var doc = new jsPDF("l","pt","a4");
      doc.html(document.querySelector("#pdf"),{
        callback: function(pdf){
          pdf.save("mypdf.pdf");
        }
      });
    }else{
      this.validation="Prvo mora biti prikazan PDF";
    }
    },
  },

  async created(){
    if(this.user.procenaKandidata!=null){
      this.allowClick=true;
      this.godiste=this.user.procenaKandidata.godiste;
      this.prebivaliste=this.user.procenaKandidata.prebivaliste;
      this.pozicija=this.user.procenaKandidata.pozicija;
      this.stepenSS=this.user.procenaKandidata.stepenSS;
      this.stepenEng=this.user.procenaKandidata.stepenEng;
      this.stepenNem=this.user.procenaKandidata.stepenNem;
      this.stepenRacunar=this.user.procenaKandidata.stepenRacunar;
      this.ans1=this.user.procenaKandidata.ans1;
      this.ans1Desc=this.user.procenaKandidata.ans1Desc;
      this.ans2=this.user.procenaKandidata.ans2;
      this.ans2Desc=this.user.procenaKandidata.ans2Desc;
      this.ans3=this.user.procenaKandidata.ans3;
      this.ans3Desc=this.user.procenaKandidata.ans3Desc;
      this.ans4=this.user.procenaKandidata.ans4;
      this.ans4Desc=this.user.procenaKandidata.ans4Desc;
      this.ans5=this.user.procenaKandidata.ans5;
      this.ans5Desc=this.user.procenaKandidata.ans5Desc;
      this.ans6=this.user.procenaKandidata.ans6;
      this.ans6Desc=this.user.procenaKandidata.ans6Desc;
      this.ans7=this.user.procenaKandidata.ans7;
      this.ans7Desc=this.user.procenaKandidata.ans7Desc;
      this.ans8=this.user.procenaKandidata.ans8;
      this.ans8Desc=this.user.procenaKandidata.ans8Desc;
      this.ans9=this.user.procenaKandidata.ans9;
      this.ans9Desc=this.user.procenaKandidata.ans9Desc;
      this.ans10=this.user.procenaKandidata.ans10;
      this.ans10Desc=this.user.procenaKandidata.ans10Desc;
      this.ans11=this.user.procenaKandidata.ans11;
      this.ans11Desc=this.user.procenaKandidata.ans11Desc;
      this.ans12=this.user.procenaKandidata.ans12;
      this.ans12Desc=this.user.procenaKandidata.ans12Desc;
      this.ans13=this.user.procenaKandidata.ans13;
      this.ans13Desc=this.user.procenaKandidata.ans13Desc;
      this.ans14=this.user.procenaKandidata.ans14;
      this.ans14Desc=this.user.procenaKandidata.ans14Desc;


      this.ukupnaOcena=0;

      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans1);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans2);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans3);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans4);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans5);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans6);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans7);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans8);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans9);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans10);
      this.ukupnaOcena+=parseInt(this.user.procenaKandidata.ans11);

      this.prosekOcena=this.ukupnaOcena/11;

    }

  }
}
</script>

<style lang="css" scoped>

.header{
  display: flex;
  flex-flow: column wrap;
}

.form-check{
  display: inline-block
}
.validation{
  margin-top: 5px;
  background-color: #00aaaa;
}
#pdf{
  /* border-style: solid;
  border-color: black;
  border-width: thin;
  padding: 30px; */

  margin: auto;

  /* background-color: #55cccc; */


}
.centered{
  text-align: center;
}
.container {


  display: grid;
  grid-auto-columns: 1fr;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;
  gap: 0px 0px;
  grid-template-areas:
    ". .";
  align-content: space-evenly;
}

.question{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8CFF90;;
  padding-left: 20px;

  height: 250px;
}

.questionAlt{
  margin-top: 20px;
  border-color: black;
  border-style: solid;
  border-width: thin;

  background-color: #8C90FF;
  padding-left: 20px;

  height: 250px;
}

#feedBackHeader{
  background-color: #ccccff;
  border-style: solid;
  border-color: black;
  border-width: medium;
  margin: 15px;
  padding: 15px;
  width: 810px;
  height: 570px;
    /* background: linear-gradient(110deg, #aaaacc 60%, #ccccff 60%); */
  text-align: center;
}
.wrap{
  border-color: black;
  border-style: solid;
  border-width: thin;
  padding: 15px;

  background-color: white;
  border-color: black;
  border-style: solid;
  border-width: thin;
  padding: 15px;
}

#svaPitanja1{
  width: 810px;
  margin: 15px;
  padding: 15px;
  height: 570px;
}

#svaPitanja2{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 15px;
  height: 570px;
}
#svaPitanja3{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 570px;
}
#svaPitanja4{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 570px;
}
#svaPitanja5{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 15px;
  height: 570px;
}

#svaPitanja6{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 30px;
  height: 570px;
}
#svaPitanja7{
  width: 810px;
  margin: 15px;
  padding: 15px;
  margin-top: 20px;
  height: 570px;
}

</style>
